import React from "react"
import { graphql } from "gatsby"
import { FormatDate, useI18next, Link } from "../i18n"
import { decodeHTMLEntities } from "../Layout/utils/utils"
import { CategoryData, PostData, TagData } from "./Blog"

type Props = {
  relatedPosts?: PostData[]
  allPosts: PostData[]
  categories: CategoryData[]
  tags: TagData[]
}

export const Sidebar: React.FC<Props> = ({ relatedPosts, allPosts, categories, tags }) => {
  const { t } = useI18next()

  return (
    <div className="blog__sidebar">
      <h3>{t("Articoli recenti")}</h3>
      {allPosts.map((post, i) => (
        <div key={i} style={{ marginBottom: "1rem" }}>
          <Link to={"post:" + post.details.uid}>{decodeHTMLEntities(post.title)}</Link>
        </div>
      ))}
      <h3 className="mt-m">{t("Categorie")}</h3>
      {categories.map((category, i) => {
        if (category.slug == category.language.slug) return null
        if (category.posts.nodes.length == 0) return null
        return (
          <div key={i} style={{ marginBottom: "1rem" }}>
            <Link to={"blog:" + category.details.uid}>
              {category.name} [{category.posts.nodes.length}]
            </Link>
          </div>
        )
      })}
      <h3 className="mt-m">{t("Tag")}</h3>
      {tags.map((tag, i) => {
        if (!tag.details.visible) return null
        if (tag.posts.nodes.length == 0) return null
        return (
          <div key={i} style={{ marginBottom: "1rem" }}>
            <Link to={"tag:" + tag.details.uid}>
              {tag.name} [{tag.posts.nodes.length}]
            </Link>
          </div>
        )
      })}
    </div>
  )
}

export default Sidebar

// Query fragment to include in graphql
export const query = graphql`
  fragment sidebarData on Wordpress {
    categories(where: { language: $languageCode }, first: 1000) {
      nodes {
        slug
        name
        details {
          uid
        }
        language {
          slug
        }
        posts(where: { orderby: { field: DATE, order: DESC } }, first: 1000) {
          nodes {
            title
            slug
            date
          }
        }
      }
    }
    tags(where: { language: $languageCode }, first: 1000) {
      nodes {
        slug
        name
        details {
          uid
          visible
        }
        posts(where: { orderby: { field: DATE, order: DESC } }, first: 1000) {
          nodes {
            title
            slug
            date
          }
        }
      }
    }
  }
`
