import React, { useState } from "react"
import Tabs from "../Layout/Components/Tabs"
import { ColumnData } from "./DynamicRow"
import { Image, Button, ImageData } from "../Layout/Components"

type Props = {
  image: ImageData
  category: string
  title: string
}

const HeroBlog: React.FC<Props> = ({ image, category, title }) => {
  return (
    <div className="hero hero__blog">
      <div className="hero__blog__cover">
        <Image file={image} type="cover" className="hero__blog__image" />
        <div className="hero__blog__overlay"></div>
      </div>
      <div className={`hero__blog__holder `}>
        <div className="hero__blog__category" dangerouslySetInnerHTML={{ __html: category }}></div>
        <h1 className="hero__blog__title" dangerouslySetInnerHTML={{ __html: title }}></h1>
      </div>
    </div>
  )
}

export default HeroBlog
