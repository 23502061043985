import React from "react"
import { graphql } from "gatsby"
import GridLayout from "../Layout/GridLayout"
import { FormatDate, useI18next, Link } from "../i18n"

import { ImageData, Image, Icon, Breadcrumbs } from "../Layout/Components"
import { DynamicRow, RowData } from "../rows/DynamicRow"
import HeroBlog from "../rows/HeroBlog"
import WPComments, { CommentData } from "../rows/WPComments"
import CommunityArticles from "../rows/CommunityArticles"

import { Seo, StructuredData, sdEcoversoPublisher } from "../seo"
import { BlogPosting, WithContext } from "schema-dts"

import { PostData, CategoryData, TagData } from "../rows/Blog"

import siteMap from "../sitemap"
import { decodeHTMLEntities } from "../Layout/utils/utils"
import Sidebar from "../rows/Sidebar"

export default (props) => {
  const { t, path } = useI18next()

  const pageUrl = props.data.site.siteMetadata.siteUrl + path

  const post = props.data.wordPress.post as PostData
  //console.log("Post data: ", props.data.wordPress)

  const relatedPosts: PostData[] = props.data.wordPress.relatedPosts.nodes
  const recentPosts: PostData[] = props.data.wordPress.recentPosts.nodes
  const categories: CategoryData[] = props.data.wordPress.categories.nodes
  const tags: TagData[] = props.data.wordPress.tags.nodes

  //console.log("Props: ", props)
  //console.log("Context: ", props.pageContext)

  const structuredData: WithContext<BlogPosting> = {
    "@context": "https://schema.org",
    "@type": "BlogPosting",
    headline: post.title,
    datePublished: post.date,
    dateModified: post.modified,
    image: ["{siteUrl}" + post.details.featuredImage.imageFile.publicURL],
    publisher: sdEcoversoPublisher,
    mainEntityOfPage: { "@type": "WebPage", "@id": "{pageUrl}" },
    author: post.details.authors.map((author) => {
      return { "@type": "Person", name: author.title }
    }),
  }

  const category = post.categories.nodes[0]
  const shareLinks = {
    facebook: `http://www.facebook.com/sharer.php?u=${pageUrl}&t=${post.title}`,
    twitter: `https://twitter.com/share?url=${pageUrl}&text=${post.title}`,
  }

  return (
    <GridLayout>
      <Seo title={post.seo.title} description={post.seo.metaDesc} isArticle image={post.details.featuredImage.imageFile.publicURL} />
      <Breadcrumbs
        data={[
          { to: "/", label: t("Home") },
          { to: siteMap.posts.it, label: t("Blog") },
          { to: "blog:" + category.details.uid, label: category.name },
          { label: post.title },
        ]}
      />
      <StructuredData data={structuredData}>
        <HeroBlog image={post.details.featuredImage} title={post.title} category={post.categories.nodes[0].name} />

        <div className="row row--center pt-none">
          <div className="blog__post">
            <div className="row row--full ">
              <div className="row row--center row--no-grid">
                <div className="blog__post-header">
                  <div className="blog__post-author-avatars">
                    {post.details.authors.map((author, i) => (
                      <Image file={author.details.photo} className="blog__post-author-avatars-avatar" type="cover" style={{ zIndex: 20 - i }} key={i} />
                    ))}
                  </div>
                  <div className="blog__post-author-names">
                    {post.details.authors.map((author, i: number) => (
                      <span key={i}>
                        {/* <StructuredData property="author" data={{ "@type": "Person", name: author.title }} /> */}
                        {author.title}
                        {i < post.details.authors.length - 2 ? t(",") : ""}
                        {i == post.details.authors.length - 2 ? t(" e") : ""}{" "}
                      </span>
                    ))}
                    <div className="blog__post-date">
                      <FormatDate date={post.date} />
                    </div>
                  </div>
                  <div className="blog__post-share">
                    <ul>
                      <li>
                        <Link to={shareLinks.facebook} aria-label="Facebook - Ecoverso" target="_blank">
                          <Icon name="facebook" className="footer__icon" />
                        </Link>
                      </li>
                      {/* <li>
                        <Link to="" aria-label="Instagram - Ecoverso" target="_blank">
                          <Icon name="instagram" />
                        </Link>
                      </li>
                      <li>
                        <Link to="#" aria-label="Youtube - Ecoverso" target="_blank">
                          <Icon name="youtube" />
                        </Link>
                      </li> */}
                      <li>
                        <Link to={shareLinks.twitter} aria-label="Twitter - Ecoverso" target="_blank">
                          <Icon name="twitter" />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            {post.details.rows?.map((row, i) => (
              <DynamicRow data={row} graphQLData={props.data} key={i} />
            ))}
            <div className="row row--full row--no-grid">
              <div className="blog__post-share blog__post-share--final">
                <ul>
                  <li className="blog__post-share-invite">{t("Condividi l'articolo")}:</li>
                  <li>
                    <Link to={shareLinks.facebook} aria-label="Facebook - Ecoverso" target="_blank">
                      <Icon name="facebook" className="footer__icon" />
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="#" aria-label="Instagram - Ecoverso" target="_blank">
                      <Icon name="instagram" />
                    </Link>
                  </li>
                  <li>
                    <Link to="#" aria-label="Youtube - Ecoverso" target="_blank">
                      <Icon name="youtube" />
                    </Link>
                  </li> */}
                  <li>
                    <Link to={shareLinks.twitter} aria-label="Twitter - Ecoverso" target="_blank">
                      <Icon name="twitter" />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <Sidebar allPosts={recentPosts} categories={categories} tags={tags} />
        </div>
        <WPComments postId={post.databaseId} comments={post.comments.nodes} />
      </StructuredData>
      <CommunityArticles
        relatedPosts={relatedPosts as any}
        allPosts={recentPosts as any}
        className={`row__background--inclined`}
        articlesType="all"
        exceptPostUid={post.details.uid}
      />
    </GridLayout>
  )
}

//query($id: ID!, $tags: [String!]) {
export const query = graphql`
  query ($id: ID!, $tags: [String!], $buyingGroupsWpIds: [ID!], $languageCode: Wordpress_LanguageCodeFilterEnum!, $relatedPostsLimit: Int) {
    site {
      siteMetadata {
        siteUrl
      }
    }
    appsync {
      ...dbBuyingGroupsData
    }
    wordPress {
      post(id: $id) {
        title
        id
        databaseId
        date
        modified
        slug
        language {
          slug
        }
        translations {
          slug
          language {
            slug
          }
        }
        seo {
          title
          metaDesc
        }
        categories {
          nodes {
            name
            slug
            details {
              uid
            }
          }
        }
        tags {
          nodes {
            name
            slug
          }
        }
        details {
          uid
          authors {
            ... on Wordpress_Person {
              id
              title
              details {
                ownedCars
                bio
                titolo
                photo {
                  ...imageData
                }
              }
            }
          }
          excerpt
          rows {
            ...postRow
          }
          featuredImage {
            ...imageData
          }
        }
        commentCount
        commentStatus
        ...postComments
      }
      ...sidebarData
      ...pageAccessoryData
    }
  }
`
