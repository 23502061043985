import React, { useContext, useEffect, useState } from "react"
import { useSelector, useDispatch } from "react-redux"
import * as actions from "../store/actions"

import { WPContext } from "../ApolloWrapper"
import { useQuery, useMutation } from "@apollo/client"
import gql from "graphql-tag"
import { graphql } from "gatsby"

import { Link, FormatDate, useI18next } from "../i18n"
import { Icon, Loading } from "../Layout/Components"

import { nominativo, email } from "../forms/fields/user"
import FormGenerator from "../Layout/Forms/FormGenerator"
import Avatar from "../Layout/Components/Avatar"

import awsconfig from "../aws-exports"

export interface CommentData extends CommentFields<CommentData> {}

//-----------------------------------------------------------------------------
// Admin Gravatars
const adminGravatarMD5 = [
  "accd4a7f43ced26bd4beb2b25919a02b", // daniele@ecoverso.org
  "1b10750f2ad148f8d5df10204e9ce965", // flavia@ecoverso.org
  "1247a86ea092f6b74fdd286da7d60f03", // luca@ecoverso.org
  "9457a948d286dad68d647d68caebfaa6", // alfredo@ecoverso.org
  "085b40cc227e236e2c5e588db8d9f2c8", // lorenzo@ecoverso.org
  "08079c81ffcc8b567bda0ac3b584acb6", // manuel@ecoverso.org
  "3e20503da0f21de272debf41e925cfcb", // alfredo@gruppoacquistoibrido.it
  "27c741df4641edfd3536916a54fcf2de", // luca@gruppoacquistoibrido.it
  "d22c9cbdd790ad9f0d885218ae73bcf7", // lorenzo@gruppoacquistoibrido.it
]

const isAdminGravatar = (gravatarURL: string) => {
  for (let i = 0; i < adminGravatarMD5.length; i++) {
    if (gravatarURL.indexOf(adminGravatarMD5[i]) != -1) return true
  }
  return false
}
//-----------------------------------------------------------------------------

type CommentFields<T> = {
  id: string
  databaseId: number
  date: string
  dateGmt: string
  approved: boolean
  content: string
  gravatarURL: string
  parentId: string | null
  author: {
    node: {
      id: string
      name: string
      url: string
    }
  }
  replies: {
    nodes: T[]
  }
}

type Props = {
  className?: string
  postId: number
  comments: CommentData[]
}

export const WPComments: React.FC<Props> = ({ className, postId, comments }) => {
  const { t } = useI18next()
  const dispatch = useDispatch()

  const [replyOn, setReplyOn] = useState(null)
  const [createCommentOnTop, setCreateCommentOnTop] = useState(false)
  const [createCommentOnBottom, setCreateCommentOnBottom] = useState(true)

  //--------------------------------------------------------------------------------------------
  // Dynamic access to WP Comments
  const WPClient = useContext(WPContext)

  const { loading, error, data, refetch } = useQuery(WPCommentsQuery, {
    variables: { id: postId },
    client: WPClient,
    // fetchPolicy: "no-cache",
  })

  //--------------------------------------------------------------------------------------------
  // Reply

  const WPCommentCreate: React.FC<{ className?: string; postId: number; databaseId?: number; onCancel?: Function }> = ({
    className,
    postId,
    databaseId,
    onCancel,
  }) => {
    const isLoggedIn = useSelector((state) => state.auth.isLoggedIn)
    const identityId = useSelector((state) => (state.auth.payload.userData ? state.auth.payload.userData["custom:identityId"] : null))
    const displayName = useSelector((state) => state.auth.payload.userData?.displayName)
    const firstName = useSelector((state) => state.auth.payload.userData?.firstName)
    const lastName = useSelector((state) => state.auth.payload.userData?.lastName)
    const userEmail = useSelector((state) => state.auth.payload.userData?.email)
    const [createComment, { error: mutationError }] = useMutation(WPCommentMutation, {
      client: WPClient,
    })

    const onSubmit = (values, setSubmitting) => {
      const url = `https://${awsconfig.aws_user_files_s3_bucket}.s3-${awsconfig.aws_user_files_s3_bucket_region}.amazonaws.com/protected/${identityId}/avatar.small.jpg`
      const valuesToSubmit = isLoggedIn ? { ...values, author: firstName + " " + lastName[0] + ".", authorEmail: userEmail, authorUrl: url } : { ...values }
      valuesToSubmit.commentOn = postId

      if (databaseId) valuesToSubmit.parent = databaseId

      //console.log("Values: ", valuesToSubmit)
      createComment({ variables: valuesToSubmit })
        .then(() => {
          setSubmitting(false)
          setReplyOn(null)
          setCreateCommentOnTop(false)
          setCreateCommentOnBottom(false)
          dispatch(actions.notificationAdd({ message: t("Commento inviato con successo"), variant: "SuccessIcon" }))
        })
        .catch((error) => {
          dispatch(actions.notificationAdd({ message: t("API Error"), variant: "DangerIcon" }))
          console.log("API Error", error, mutationError)
        })
    }

    const content = {
      title: "Commento",
      type: "string",
      fieldType: "textarea",
      required: true,
      fieldProperties: {
        //placeholder: "Scrivi qui il tuo commento",
        autoSize: { minRows: 5, maxRows: 10 },
      },
    }

    const commentForm = isLoggedIn
      ? { content }
      : {
          row1: {
            type: "row",
            properties: {
              author: { ...nominativo /*title: null, fieldProperties: { placeholder: "Nome" }*/ },
              authorEmail: { ...email /*title: null, fieldProperties: { placeholder: "Email" }*/ },
            },
          },
          content,
        }

    return <FormGenerator properties={commentForm} className="comments-form" onCancel={onCancel} onSubmit={onSubmit} hideCancelButton={onCancel == undefined} />
  }

  //--------------------------------------------------------------------------------------------
  // Comments Rendering

  let commentsCount = 0

  const [totalComments, setTotalComments] = useState(commentsCount)

  const renderComments = (comments: CommentData[], level = 1) => {
    if (level == 1) commentsCount = 0
    if (comments == null) return null
    const rendering = comments.map((comment, i) => {
      if (level == 1 && comment.parentId != null) return
      const replies = renderComments(comment.replies?.nodes, level + 1)
      commentsCount++
      return (
        <div className={"card__comment" + (isAdminGravatar(comment.gravatarURL) ? " card__comment--admin" : "")} key={i}>
          <div className="card__comment-avatar">
            <Icon name="user" size="l" className="card__comment-avatar-placeholder" />
            <img src={comment.gravatarURL} className="card__comment-avatar-image" alt={`Avatar for ${comment.author.node.name}`} />
            <Avatar url={comment.author.node.url} className="card__comment-avatar-image" alt={`Avatar for ${comment.author.node.name}`} />
          </div>
          <div className="card__comment-content-holder">
            <div className="card__comment-author">{comment.author.node.name}</div>
            <div className="card__comment-content" dangerouslySetInnerHTML={{ __html: comment.content }} />
            <div className="card__comment-info">
              <div>
                {level <= 2 && replyOn != comment.databaseId ? (
                  <div className="card__comment-button" onClick={() => setReplyOn(comment.databaseId)}>
                    {t("Rispondi")}
                  </div>
                ) : null}
              </div>
              <FormatDate date={comment.date} format="d MMMM yyyy - H:mm" parseFormat="yyyy-MM-dd HH:mm:ss" />
            </div>
            {replyOn == comment.databaseId ? <WPCommentCreate postId={postId} databaseId={comment.databaseId} onCancel={() => setReplyOn(null)} /> : null}
            <div className="card__comment-replies">{replies}</div>
          </div>
        </div>
      )
    })
    if (level == 1 && totalComments != commentsCount) setTotalComments(commentsCount)
    return rendering
  }

  //--------------------------------------------------------------------------------------------

  const [updatedComments, setUpdatedComments] = useState(comments)

  useEffect(() => {
    // Update comments with the ones from wordpress ;-)
    if (!loading && !error) {
      setUpdatedComments(data.comments.nodes)
      //console.log(data.comments.nodes)
    }
  }, [data, error, loading])

  return (
    <div className="row row--full pt-none">
      {/* <div className="row row--full ">
        <div className="row row--center row--no-grid">
          <pre style={{ fontSize: "12px", lineHeight: "14px" }}>{JSON.stringify(loading, null, 2)}</pre>
          <pre style={{ fontSize: "12px", lineHeight: "14px" }}>{JSON.stringify(error, null, 2)}</pre>
          <pre style={{ fontSize: "12px", lineHeight: "14px" }}>{JSON.stringify(data, null, 2)}</pre>
        </div>
      </div> */}
      <div className="row row--center row--no-grid">
        <div className="comments-header">
          <div>
            {totalComments + " " + t("Commenti")}
            {loading ? <img src="/img/ecoverso.spinner.gif" style={{ height: "1rem", marginLeft: "1rem" }} /> : null}
          </div>
          {!createCommentOnTop ? (
            <div className="comments-header-button" onClick={() => setCreateCommentOnTop(true)}>
              Aggiungi un commento <Icon name="plus" size="s" strokeWidth={2} />
            </div>
          ) : null}
        </div>
        {createCommentOnTop ? <WPCommentCreate postId={postId} onCancel={() => setCreateCommentOnTop(false)} /> : null}
        {/* {renderedComments} */}
        {renderComments(updatedComments)}
        {setCreateCommentOnBottom ? (
          <>
            <div className="comments-create-header">{t("Commenta")}</div>
            <WPCommentCreate postId={postId} />
          </>
        ) : null}
      </div>
    </div>
  )
}

const WPCommentsQuery = gql`
  query GetWPComments($id: ID!) {
    comments(where: { contentId: $id, contentStatus: PUBLISH }, first: 1000) {
      nodes {
        ...recoursiveComment
      }
    }
  }

  fragment commentFields on Comment {
    id
    databaseId
    date
    dateGmt
    approved
    content
    gravatarURL
    parentId
    author {
      node {
        id
        name
        url
      }
    }
  }

  fragment recoursiveComment on Comment {
    ...commentFields
    replies(first: 1000) {
      nodes {
        ...commentFields
        replies(first: 1000) {
          nodes {
            ...commentFields
            replies(first: 1000) {
              nodes {
                ...commentFields
                replies(first: 1000) {
                  nodes {
                    ...commentFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`
const WPCommentMutation = gql`
  mutation MyMutation($author: String!, $authorEmail: String!, $authorUrl: String, $commentOn: Int!, $parent: ID, $content: String!) {
    createComment(
      input: {
        author: $author
        authorEmail: $authorEmail
        authorUrl: $authorUrl
        commentOn: $commentOn
        content: $content
        parent: $parent
        clientMutationId: "CreateComment"
      }
    ) {
      success
      comment {
        databaseId
        approved
      }
    }
  }
`

// Query fragment to include in graphql
export const query = graphql`
  fragment commentFields on Wordpress_Comment {
    id
    databaseId
    date
    dateGmt
    approved
    content
    gravatarURL
    parentId
    author {
      node {
        id
        name
        url
      }
    }
  }
  fragment recoursiveComment on Wordpress_Comment {
    ...commentFields
    replies(first: 1000) {
      nodes {
        ...commentFields
        replies(first: 1000) {
          nodes {
            ...commentFields
            replies(first: 1000) {
              nodes {
                ...commentFields
                replies(first: 1000) {
                  nodes {
                    ...commentFields
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  fragment postComments on Wordpress_Post {
    comments(where: { orderby: COMMENT_DATE }, first: 1000) {
      nodes {
        ...recoursiveComment
      }
    }
  }
  fragment buyingGroupComments on Wordpress_BuyingGroup {
    comments(where: { orderby: COMMENT_DATE }, first: 1000) {
      nodes {
        ...recoursiveComment
      }
    }
  }
`

export default WPComments
